  var reflesh = function(){
    var ad_el_arr = document.getElementsByClassName("ad_container");
    for(var i=0; i< ad_el_arr.length; i++){
      var ad_el = ad_el_arr[i];
      var ad_iframe_el = ad_el.querySelector(".ad_iframe");
      if(ad_iframe_el){
        var src = ad_iframe_el.getAttribute("src");
        var width = ad_iframe_el.getAttribute("width");
        var height = ad_iframe_el.getAttribute("height");

        var iframe = document.createElement("iframe");
        iframe.src=src;
        iframe.width = width;
        iframe.height = height;
        iframe.className="ad_iframe";
        // iframe.style.margin = "0px";
        // iframe.style.borderWidth = "0px";
        // iframe.style.padding = "0px";
        iframe.scrolling = "no";
        iframe.frameBorder = "0";
        ad_el.innerHTML = "";
        ad_el.appendChild(iframe);
      }
    }
  }
